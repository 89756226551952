import { gql } from "@apollo/client";
import { User } from "./generated/types";

export type BaseUserFields = Pick<
  User,
  | "__typename"
  | "id"
  | "email"
  | "name"
  | "phoneNumber"
  | "isSuperuser"
  | "createdAt"
  | "updatedAt"
>;

export const BaseUserFields = gql`
  fragment BaseUserFields on User {
    id
    email
    avatarImageUrl
    name
    phoneNumber
    isSuperuser
    createdAt
    updatedAt
  }
`;
