import { gql } from "@apollo/client";
import { BaseUserFields } from "./fragments";

// Cache stuff: https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies

// === types === //

// ==== users ==== //
const CreateUser = gql`
  ${BaseUserFields}
  mutation CreateUser(
    $name: String!
    $email: String!
    $phoneNumber: String
    $referralCode: String
    $referredDomainUrl: String
  ) {
    createUser(
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      referralCode: $referralCode
      referredDomainUrl: $referredDomainUrl
    ) {
      ...BaseUserFields
    }
  }
`;

const DeleteUser = gql`
  mutation DeleteUser($email: String!) {
    deleteUser(email: $email)
  }
`;

const DeleteMe = gql`
  mutation DeleteMe {
    deleteMe
  }
`;

const UpdateUser = gql`
  ${BaseUserFields}
  mutation UpdateUser($name: String, $hasTwoFactorAuth: Boolean) {
    updateUser(name: $name, hasTwoFactorAuth: $hasTwoFactorAuth) {
      ...BaseUserFields
    }
  }
`;

const UnenrollTwoFactor = gql`
  mutation UnenrollTwoFactor {
    unenrollTwoFactor
  }
`;

const CreateUserWithEmail = gql`
  ${BaseUserFields}
  mutation CreateUserWithEmail(
    $name: String!
    $email: String!
    $password: String!
    $phoneNumber: String
    $referralCode: String
    $referredDomainUrl: String
  ) {
    createUserWithEmail(
      name: $name
      email: $email
      password: $password
      phoneNumber: $phoneNumber
      referralCode: $referralCode
      referredDomainUrl: $referredDomainUrl
    ) {
      user {
        ...BaseUserFields
      }
      customToken
    }
  }
`;

const GetMe = gql`
  ${BaseUserFields}
  query GetMe {
    me {
      ...BaseUserFields
    }
  }
`;

const GetPaymentMethods = gql`
  query GetPaymentMethods {
    getPaymentMethods {
      last4
      source
    }
  }
`;

const CreateCheckoutSession = gql`
  mutation CreateCheckoutSession(
    $interval: SubscriptionPlanTypeEnum!
    $senderName: String!
    $senderEmail: String!
    $recipientPhoneNumber: String!
    $recipientName: String!
    $recipientEmail: String!
  ) {
    createCheckoutSession(
      interval: $interval
      senderName: $senderName
      senderEmail: $senderEmail
      recipientPhoneNumber: $recipientPhoneNumber
      recipientName: $recipientName
      recipientEmail: $recipientEmail
    )
  }
`;

export const api = {
  users: {
    create: CreateUser,
    delete: DeleteUser,
    deleteMe: DeleteMe,
    update: UpdateUser,
    unenrollTwoFactor: UnenrollTwoFactor,
    createWithEmail: CreateUserWithEmail,
    me: GetMe,
    paymentMethods: GetPaymentMethods,
  },
  checkouts: {
    start: CreateCheckoutSession,
  },
};
