import { useMutation } from "@apollo/client";
import { Spinner, Text } from "@chakra-ui/react";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useEffect, useMemo, useState } from "react";
import { api } from "src/api";
import { Mutation, SubscriptionPlanTypeEnum } from "src/api/generated/types";
import { Button } from "src/components/Button";
import { useTheme } from "src/hooks/useTheme";
import { stripePromise } from "src/utils/stripe";

const FullPaymentForm = () => {
  const [isReady, setReady] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();

  useEffect(() => {
    if (!elements) return;
    // make dark mode if applicable
    elements?.update({
      appearance: { theme: theme.theme === "dark" ? "night" : "stripe" },
    });
  }, [elements, theme.theme]);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements || !isReady) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <div style={{ padding: "2rem 0 0 0" }}>
      <div
        style={{
          margin: "0 0.25rem",
        }}
      >
        {/* <CheckoutSummary subCheckout={subCheckout} /> */}
        <PaymentElement options={{}} onReady={() => setReady(true)} />
        {!isReady && (
          <Text fontSize="md" style={{ alignItems: "center", display: "flex" }}>
            <Spinner color={theme.header} size="md" />
          </Text>
        )}
      </div>
      <Button
        style={{
          width: "100%",
          margin: "3rem 0.25rem 1.5rem 0.25rem",
        }}
        variant="primary"
        onClick={handleSubmit}
        disabled={!isReady}
      >
        Purchase Tax Report
      </Button>
    </div>
  );
};

export const Payment = ({
  interval,
}: {
  interval: SubscriptionPlanTypeEnum;
}) => {
  const variables = useMemo(() => {
    return {
      interval:
        interval.toLowerCase() === "unlimited"
          ? SubscriptionPlanTypeEnum.Unlimited
          : SubscriptionPlanTypeEnum.HourAMonth,
    };
  }, [interval]);

  const [createCheckoutSession, { data }] = useMutation<
    Pick<Mutation, "createCheckoutSession">
  >(api.checkouts.start);

  useEffect(() => {
    createCheckoutSession({ variables });
  }, [createCheckoutSession, variables]);

  const clientSecret = data?.createCheckoutSession;

  console.log(data);
  if (!clientSecret) {
    return null;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
      }}
    >
      <FullPaymentForm />
    </Elements>
  );
};
