export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date custom scalar type */
  Date: { input: Date; output: Date; }
};

export enum BillingInterval {
  Monthly = 'monthly',
  Yearly = 'yearly'
}

export type Conversation = {
  __typename?: 'Conversation';
  callSummary?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  durationFormatted?: Maybe<Scalars['String']['output']>;
  durationMs?: Maybe<Scalars['Int']['output']>;
  groupMemberId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  rawText?: Maybe<Scalars['String']['output']>;
  recordingUrl?: Maybe<Scalars['String']['output']>;
  retellCallId?: Maybe<Scalars['String']['output']>;
  shortSummary?: Maybe<Scalars['String']['output']>;
  status: ConversationStatusEnum;
  title?: Maybe<Scalars['String']['output']>;
  transcript: Array<TranscriptMessage>;
  transcriptionUrl?: Maybe<Scalars['String']['output']>;
};

export enum ConversationStatusEnum {
  Completed = 'Completed',
  InProgress = 'InProgress',
  Missed = 'Missed',
  None = 'None',
  Started = 'Started'
}

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  token: Scalars['String']['output'];
  user: User;
};

export type DailyPrompt = {
  __typename?: 'DailyPrompt';
  conversations?: Maybe<Array<Conversation>>;
  createdAt: Scalars['Date']['output'];
  familyMemberId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isCompleted: Scalars['Boolean']['output'];
  position: Scalars['Int']['output'];
  prompt: Scalars['String']['output'];
};

export type DeleteUserResponse = {
  __typename?: 'DeleteUserResponse';
  message: Scalars['String']['output'];
};

export type HasSubscriptionResponse = {
  __typename?: 'HasSubscriptionResponse';
  hasActiveSubscription?: Maybe<Scalars['Boolean']['output']>;
  hasSubscription?: Maybe<Scalars['Boolean']['output']>;
  subscription?: Maybe<Subscription>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCheckoutSession: Scalars['String']['output'];
  createPaymentLink: Scalars['String']['output'];
  createUser: CreateUserResponse;
  deleteUser: DeleteUserResponse;
  sendVerificationCode: Scalars['String']['output'];
  startCall?: Maybe<Conversation>;
  updateMe: User;
  verifyPhone: Scalars['String']['output'];
};


export type MutationCreateCheckoutSessionArgs = {
  interval: SubscriptionPlanTypeEnum;
  recipientEmail: Scalars['String']['input'];
  recipientName: Scalars['String']['input'];
  recipientPhoneNumber: Scalars['String']['input'];
  senderEmail: Scalars['String']['input'];
  senderName: Scalars['String']['input'];
};


export type MutationCreatePaymentLinkArgs = {
  interval: SubscriptionPlanTypeEnum;
};


export type MutationCreateUserArgs = {
  email: Scalars['String']['input'];
  isElder?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  referralCode?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendVerificationCodeArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type MutationUpdateMeArgs = {
  helpDescription?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationVerifyPhoneArgs = {
  phoneNumber: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  createdAt: Scalars['Date']['output'];
  familyId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isVerified: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type PhoneNumberResponse = {
  __typename?: 'PhoneNumberResponse';
  needsSubscription?: Maybe<Scalars['Boolean']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  getBillingPortalLink: Scalars['String']['output'];
  getConversation: Conversation;
  getConversations: Array<Conversation>;
  getHasSubscription: HasSubscriptionResponse;
  getPhoneNumberToCall?: Maybe<PhoneNumberResponse>;
  getSubscription: Subscription;
  me?: Maybe<User>;
};


export type QueryGetConversationArgs = {
  conversationId: Scalars['ID']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  lastChargedAt: Scalars['Date']['output'];
  paidByUserId?: Maybe<Scalars['String']['output']>;
  paidForName?: Maybe<Scalars['String']['output']>;
  planType: SubscriptionPlanTypeEnum;
  stripeCustomerId: Scalars['String']['output'];
  stripeSubscriptionId: Scalars['String']['output'];
  subscribedUserId?: Maybe<Scalars['String']['output']>;
  subscribedUserPhoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export enum SubscriptionPlanTypeEnum {
  HourAMonth = 'HourAMonth',
  Unlimited = 'Unlimited'
}

export type TranscriptMessage = {
  __typename?: 'TranscriptMessage';
  content: Scalars['String']['output'];
  role: Scalars['String']['output'];
  words: Array<TranscriptMessageWord>;
};

export type TranscriptMessageWord = {
  __typename?: 'TranscriptMessageWord';
  end?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['Float']['output']>;
  word?: Maybe<Scalars['String']['output']>;
};

export type User = {
  __typename?: 'User';
  activeGroupId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isSuperuser: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export enum UserTypeEnum {
  Pending = 'Pending',
  Verified = 'Verified'
}
