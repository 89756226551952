export const config = {
  env: process.env.NODE_ENV || "local",
  apiUrl: process.env.REACT_APP_API_URL || "http://localhost:8080",
  stripe: {
    publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY || "",
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
    appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "",
  },
  redux: {
    logger: process.env.REACT_APP_REDUX_LOGGER === "true",
  },
};

export const isProduction = config.env === "production";
