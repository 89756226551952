import { useMutation } from "@apollo/client";
import { Box, Heading, HStack, Text } from "@chakra-ui/react";
import parsePhoneNumberFromString, { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "src/api";
import {
  Mutation,
  MutationCreateCheckoutSessionArgs,
  SubscriptionPlanTypeEnum,
} from "src/api/generated/types";
import { Button } from "src/components/Button";
import { Input } from "src/components/Form";
import { useLocalStorage } from "src/hooks/common";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { Payment } from "./Payment";

export type StepOption = "onboarding" | "user" | "plan" | "payment" | "success";

export function NewOnboarding() {
  const navigate = useNavigate();

  // get the most recent path element
  const path = window.location.pathname.split("/").pop();

  // send name
  const [senderName, setSenderName] = useLocalStorage("sender_name", "");

  // sender email
  const [senderEmail, setSenderEmail] = useLocalStorage("sender_email", "");

  const [recipientName, setRecipientName] = useLocalStorage(
    "recipient_name",
    ""
  );
  // phone
  const [recipientPhone, setRecipientPhone] = useLocalStorage(
    "recipient_phone",
    ""
  );

  const [pricingOption, setPricingOption] =
    useLocalStorage<SubscriptionPlanTypeEnum>(
      "pricing_option",
      SubscriptionPlanTypeEnum.Unlimited
    );

  // the flow is onboarding screen => who are you (ex. is it for you or family member)
  // then it goes to add to contacts => test call => add to contacts again (just to make sure they actually added)
  // and then goes to actual signup page to signup for Ruth
  const [step, setStep] = React.useState<StepOption>(path as any);

  useEffect(() => setStep(path as any), [path]);

  const [createCheckoutSession] = useMutation<
    Pick<Mutation, "createCheckoutSession">
  >(api.checkouts.start);

  const _getPhoneNumber = (): string | null => {
    // parse the phone number to E.164
    const rawPhone = recipientPhone || "";

    // if it starts with a +, then it's already in E.164 format
    if (rawPhone.startsWith("+")) {
      return rawPhone;
    }

    // otherwise parse it into E.164 using the US country code
    const parsedPhoneNumber = parsePhoneNumberFromString(rawPhone, "US");

    if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
      return parsedPhoneNumber.format("E.164");
    }

    // return null if parsing fails or the number is invalid
    return null;
  };

  const _startCheckout = async () => {
    try {
      const phoneNumber = _getPhoneNumber();

      if (!phoneNumber) {
        alert("Invalid phone number");
        return;
      }

      const variables: MutationCreateCheckoutSessionArgs = {
        interval:
          pricingOption === SubscriptionPlanTypeEnum.Unlimited
            ? SubscriptionPlanTypeEnum.Unlimited
            : SubscriptionPlanTypeEnum.HourAMonth,
        senderName,
        senderEmail,
        recipientPhoneNumber: phoneNumber,
        recipientName,
        recipientEmail: "",
      };

      const checkoutSession = await createCheckoutSession({ variables });

      console.log(checkoutSession.data);

      const url = checkoutSession.data?.createCheckoutSession;

      if (url) {
        window.location.href = url;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const _onNextStep = async () => {
    if (step === "onboarding") {
      navigate("/onboarding/plan");
    } else if (step === "plan") {
      // go to payment

      await _startCheckout();

      return;
    }
    // } else if (step === "who-are-you") {
    //   setIsForMe(isForMe);

    //   navigate("/onboarding/add-to-contacts");
    // } else if (step === "add-to-contacts") {
    //   setIsForMe(isForMe);
    //   setHasClickedAdd(false);
    //   navigate("/onboarding/test-call");
    // } else if (step === "test-call") {
    //   setHasClickedAdd(false);
    //   navigate("/onboarding/add-to-contacts-retry");
    // } else if (step === "add-to-contacts-retry") {
    //   // add is for me and phone number to query params
    //   navigate(
    //     "/signup?isElder=" + isForMe + "&phoneNumber=" + _getPhoneNumber()
    //   );
    // }
  };

  const theme = useTheme();
  const isLarge = useIsLargeScreen();

  return (
    <div
      style={{
        backgroundColor: colors.primary,
      }}
    >
      <HStack
        padding="0"
        minH="100vh"
        display="flex"
        w="100%"
        h="100vh"
        gap={0}
      >
        <Box
          display="flex"
          flexDir="column"
          h="100%"
          overflowY="scroll"
          w={isLarge ? "65%" : "100%"}
          justifyContent="flex-start"
          marginRight={0}
          padding="2rem"
          bg={theme.background}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              maxWidth: 650,
              paddingTop: 0,
            }}
          >
            <Heading
              textAlign="left"
              color={theme.header}
              marginTop="0"
              fontSize="5xl"
              fontWeight="extrabold"
              marginBottom="1.5rem"
            >
              The perfect present for
              <br />
              your{" "}
              <TextRotator
                words={["papa", "nana", "dad", "mom", "gramps", "granny"]}
                interval={2000}
              />
            </Heading>

            <Steps
              onChangeStep={(newPath) => navigate(newPath)}
              currentStep={step}
            />

            <br />

            {step === "onboarding" && (
              <>
                <Text
                  marginTop="1rem"
                  marginBottom="0.5rem"
                  color={theme.header}
                  fontSize="md"
                >
                  Who are you buying Duca for?
                </Text>

                <Input
                  // label="Name"
                  placeholder="Enter name"
                  value={recipientName}
                  onChange={(e) => setRecipientName(e.target.value)}
                />

                <Text
                  marginBottom="0.25rem"
                  marginTop="1.5rem"
                  color={theme.header}
                  fontSize="md"
                >
                  What's their phone number?
                </Text>

                <Text
                  marginTop="0"
                  marginBottom="1rem"
                  color={theme.text}
                  fontSize="sm"
                  fontWeight="normal"
                >
                  We'll never share this with anyone 🛡️
                </Text>

                <Input
                  // label="Name"
                  placeholder="Phone number"
                  value={new AsYouType("US").input(recipientPhone || "")}
                  onChange={(e) => setRecipientPhone(e.target.value)}
                />

                <br />
              </>
            )}

            {step === "plan" && (
              <>
                <Text
                  marginBottom="0.5rem"
                  marginTop="1.5rem"
                  color={theme.header}
                  fontSize="md"
                >
                  What's your name?
                </Text>

                <Input
                  // label="Name"
                  placeholder="Name"
                  value={senderName}
                  onChange={(e) => setSenderName(e.target.value)}
                />

                <Text
                  marginBottom="0.5rem"
                  marginTop="1.5rem"
                  color={theme.header}
                  fontSize="md"
                >
                  What's your email?
                </Text>

                <Text
                  marginBottom="1rem"
                  color={theme.text}
                  fontWeight="normal"
                  fontSize="16px"
                >
                  This is only for your receipt. We'll never share this with
                  anyone 🛡️
                </Text>

                <Input
                  // label="Name"
                  placeholder="Email"
                  value={senderEmail}
                  onChange={(e) => setSenderEmail(e.target.value)}
                />

                <br />

                <Text
                  style={{
                    fontSize: 18,
                    color: theme.header,
                    fontWeight: "normal",
                  }}
                  marginBottom="0.25rem"
                >
                  What plan would you like to purchase for {recipientName}?
                </Text>

                <Text
                  style={{
                    color: theme.text,
                    fontSize: 16,
                    fontWeight: "normal",
                  }}
                  marginBottom="1rem"
                >
                  All plans include 12 months of access.
                </Text>

                {/* two options, can click a circle and color it in. me or "for someone else" */}

                <div>
                  <Option
                    label="Limited plan"
                    subtitle="This includes 45 minute of help per month."
                    isActive={
                      pricingOption === SubscriptionPlanTypeEnum.HourAMonth
                    }
                    priceFormatted="$69"
                    onSelect={() =>
                      setPricingOption(SubscriptionPlanTypeEnum.HourAMonth)
                    }
                  />
                  <Option
                    label="Unlimited Plan"
                    subtitle="Unlimited help, whenever they need it."
                    isActive={
                      pricingOption === SubscriptionPlanTypeEnum.Unlimited
                    }
                    priceFormatted="$99"
                    onSelect={() =>
                      setPricingOption(SubscriptionPlanTypeEnum.Unlimited)
                    }
                  />
                </div>

                {/* <div>
                  Warning: only get the unlimited plan if you really want your
                  grandparent to love you 😉
                </div> */}
                <br />
              </>
            )}

            {step === "payment" && <Payment interval={pricingOption} />}

            {/* success screen */}
            {step === "success" && (
              <>
                <br />
                <div style={{ textAlign: "left", padding: "0" }}>
                  <i
                    className="fa fa-check-circle"
                    style={{
                      color: colors.primary,
                      fontSize: "5rem",
                      marginBottom: "0",
                    }}
                  />

                  <Heading
                    color={theme.header}
                    fontSize="4xl"
                    marginBottom="1rem"
                  >
                    Success
                  </Heading>
                  <Text color={theme.text} fontSize="lg" marginBottom="1rem">
                    Thank you for your purchase!
                    <br />
                    <br />
                    When your loved one signs up using their phone{" "}
                    {recipientPhone}, they'll automatically be linked to the
                    subscription you purchased for them.
                    <br />
                    <br />
                    All you need to do is one thing, <b>share Duca with them</b>
                    .<br />
                    <br />
                    You can do this by:
                    <br />
                    <ul
                      style={{
                        marginTop: 10,
                        paddingLeft: 5,
                        listStyleType: "disc",
                        listStylePosition: "inside",
                        lineHeight: 1.75,
                      }}
                    >
                      <li>
                        Printing out{" "}
                        <a
                          href="https://firebasestorage.googleapis.com/v0/b/thequickfix-app.appspot.com/o/help%2Fguide-sm.png?alt=media&token=aefc8428-77ef-4487-ae28-b23f15bb9401"
                          target="_blank"
                          style={{
                            color: colors.primary,
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          this guide
                        </a>{" "}
                        to give to them
                      </li>

                      <li>
                        Sharing the url{" "}
                        <a
                          href="https://askduca.com/go"
                          target="_blank"
                          style={{
                            color: colors.primary,
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          https://askduca.com/go
                        </a>
                      </li>
                    </ul>
                    <br />
                    <br />
                    PS. If you need any email, just email us at{" "}
                    <b>team@askduca.com</b>.
                  </Text>
                </div>
              </>
            )}

            {step !== "success" && (
              <Button
                width="100%"
                variant="primary"
                type="submit"
                onClick={_onNextStep}
              >
                Continue{" "}
                <i
                  style={{
                    marginLeft: 5,
                  }}
                  className="fa fa-arrow-right"
                />
              </Button>
            )}
          </div>
        </Box>

        {isLarge && (
          <div
            style={{
              flex: 1,
              height: "100%",
              borderLeft: `1px solid ${colors.gray80}`,
              minHeight: "100vh",
              background: `linear-gradient(to bottom right, ${colors.gray90} 0%, ${colors.gray100} 100%)`,
              // background of SignupImage, contain
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
                marginBottom: "3rem",
              }}
            >
              <Testimonial />
            </div>

            <video
              controls={false}
              width="70%"
              className="videoPlayer"
              muted
              autoPlay
              loop
              style={{
                borderRadius: 20,
                margin: "auto",
                border: `1px solid ${colors.gray80}`,
              }}
            >
              <source
                src="https://firebasestorage.googleapis.com/v0/b/thequickfix-app.appspot.com/o/help%2Fnew-demo-cp.mp4?alt=media&token=b27aa0b5-94dc-41b3-bcb2-868a7dc2b2d8"
                type="video/mp4"
              />
            </video>
          </div>
        )}
      </HStack>
    </div>
  );
}

const Step = ({
  label,
  isActive,
  onClick,
}: {
  label: string;
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <Box
      onClick={onClick}
      style={{
        cursor: "pointer",
        flex: 1,
        textAlign: "center",
        margin: "0 2px",
        // border: `1px solid ${colors.gray80}`,
        // borderRadius: 10,
        padding: 7,
      }}
    >
      <Text
        style={{
          color: colors.black,
          fontWeight: "normal",
          marginBottom: 5,
          fontSize: 18,
          textAlign: "left",
        }}
      >
        {label}
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: 7,
            borderRadius: 100,
            flex: 1,
            background: isActive ? colors.primary : colors.gray80,
          }}
        />
        {isActive && (
          <i
            className="fa fa-check-circle"
            style={{
              color: isActive ? colors.primary : colors.gray85,
              fontSize: 10,
              marginLeft: 3,
            }}
          />
        )}
      </div>
    </Box>
  );
};

const Steps = ({
  currentStep,
  onChangeStep,
}: {
  currentStep: StepOption;
  onChangeStep: (p: string) => void;
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        // border: `1px solid ${colors.gray80}`,
        borderRadius: 20,
        background: colors.gray90,
        padding: 5,
      }}
    >
      <Step
        label="Gift"
        isActive={true}
        onClick={() => onChangeStep("/onboarding")}
      />

      {/* <i className="fa fa-arrow-right" style={{ color: colors.gray70 }} /> */}

      <Step
        label="Billing"
        isActive={currentStep === "plan" || currentStep === "success"}
        onClick={() => onChangeStep("/onboarding/plan")}
      />

      {/* <i className="fa fa-arrow-right" style={{ color: colors.gray70 }} /> */}

      <Step
        onClick={() => {
          //
        }}
        label="Success"
        isActive={currentStep === "success"}
      />
    </div>
  );
};

const Option = ({
  onSelect,
  label,
  subtitle,
  priceFormatted,
  isActive,
}: {
  onSelect: () => void;
  label: string;
  subtitle?: string;
  isActive: boolean;
  priceFormatted: string;
}) => {
  const theme = useTheme();

  return (
    <HStack style={{ marginBottom: 15, cursor: "pointer" }} onClick={onSelect}>
      <div
        style={{
          backgroundColor: isActive ? colors.primary : theme.ternaryBackground,
          width: 30,
          height: 30,
          borderRadius: 100,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isActive ? (
          <i className="fas fa-check" style={{ color: colors.white }} />
        ) : null}
      </div>

      <div
        style={{
          flex: 1,
        }}
      >
        <Text className="spectral-semibold" fontSize="lg" fontWeight="600">
          {label}
        </Text>

        <Text className="spectral-semibold" fontSize="sm" fontWeight="400">
          {subtitle}
        </Text>
      </div>

      <Text
        style={{
          fontSize: 24,
        }}
        fontWeight="400"
      >
        <span
          style={{
            color: colors.primary,
            fontWeight: "bold",
          }}
        >
          {priceFormatted}
        </span>
      </Text>
    </HStack>
  );
};

const TextRotator = ({ words }: { words: string[]; interval: number }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const rotation = setInterval(() => {
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
      }, 2_000); // Duration of fade-out
    }, 2_000);

    return () => clearInterval(rotation);
  }, []);

  return (
    <span
      style={{
        transition: "opacity 0.5s",
        opacity: 1,
        fontWeight: "900",
        color: colors.primary,
        textDecoration: "underline",
      }}
    >
      {words[currentIndex]}
    </span>
  );
};

const Testimonial = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        position: "relative",
        padding: 30,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: 0,
        background: `linear-gradient(to bottom right, ${colors.lightBlue50} 0%, ${colors.primary} 100%)`,
      }}
    >
      <HStack spacing={1} align="center">
        <i className="fa fa-star" style={{ color: colors.yellow60 }} />
        <i className="fa fa-star" style={{ color: colors.yellow60 }} />
        <i className="fa fa-star" style={{ color: colors.yellow60 }} />
        <i className="fa fa-star" style={{ color: colors.yellow60 }} />
        <i className="fa fa-star" style={{ color: colors.yellow60 }} />
      </HStack>
      <Text
        style={{
          position: "relative",
          top: -5,
          marginTop: 0,
          color: colors.white,
          fontWeight: "600",
        }}
      >
        <i
          className="fa fa-quote-left"
          style={{ color: colors.white, position: "absolute", left: 5 }}
        />
        <br />
        Somehow it always knows the answer to my questions... I don't know how
        it does it!
        <br />- Sue
        <div
          style={{
            textAlign: "right",
            position: "absolute",
            bottom: 5,
            right: 5,
          }}
        >
          <i
            className="fa fa-quote-right"
            style={{ color: colors.white, alignSelf: "flex-end" }}
          />
        </div>
      </Text>
    </div>
  );
};
