import { useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAuth } from "./useAuth";

import { useApolloClient } from "@apollo/client";
import { Spinner } from "@chakra-ui/react";
import { signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "src/hooks/useTheme";
import { setTheme } from "src/redux/reducers/globalState";
import { getUserAuthStatus } from "src/redux/reducers/user";
import { AuthStatus } from "src/redux/types";
import { auth } from "src/utils/firebase";
import { Login } from "src/views/Authentication";
import { NewOnboarding } from "src/views/NewOnboarding";

const ProtectedRoute = ({
  authStatus,
  children,
}: {
  authStatus: AuthStatus;
  children: JSX.Element;
}) => {
  const theme = useTheme();

  if (authStatus === "LOADING" || authStatus === "NOT_LOADED") {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.background,
        }}
      >
        <Spinner color={theme.header} />
      </div>
    );
  }

  if (authStatus === "NOT_LOGGED_IN") {
    const path = window.location.pathname;
    // add the search params if there are any as well
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const searchString = params.toString();
    return (
      <Navigate
        to={`/login?redirect=${path}${searchString ? "&" + searchString : ""}`}
        replace
      />
    );
  }

  return children;
};

export const MainRoutes = () => {
  const { pathname } = useLocation();
  const status = useSelector(getUserAuthStatus);

  const theme = useTheme();

  useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const dispatch = useDispatch();

  useEffect(() => {
    // if dark, set the html to have the theme.background color
    document.documentElement.style.backgroundColor = theme.background;
  }, [theme.background]);

  useEffect(() => {
    const theme = window.localStorage.getItem("theme");
    dispatch(setTheme(theme === "dark" ? "dark" : "light"));
  }, []);

  // return <MaintenanceOverride />;

  return (
    <Routes>
      <Route path="" element={<div>hello world</div>} />
      <Route path="login" element={<Login />} />
      <Route path="onboarding/*" element={<NewOnboarding />} />
      <Route path="logout" element={<Logout />} />
      {/* <Route
        path="dashboard/*"
        element={
          <ProtectedRoute authStatus={authStatus}>
            <DashboardRoutes />
          </ProtectedRoute>
        }
      /> */}
    </Routes>
  );
};

const Logout = () => {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();

  useEffect(() => {
    signOut(auth)
      .then(() => apolloClient.cache.reset())
      .then(() => navigate("/login"));
  }, []);

  return null;
};

export const Navigation = () => (
  <BrowserRouter>
    <MainRoutes />
  </BrowserRouter>
);
