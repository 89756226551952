import { useLazyQuery, useMutation } from "@apollo/client";
import { Text } from "@chakra-ui/react";
import { UserCredential } from "firebase/auth";
import React, { useCallback } from "react";
import { api } from "src/api";
import { BaseUserFields } from "src/api/fragments";
import {
  Mutation,
  MutationCreateUserArgs,
  Query,
} from "src/api/generated/types";
import { Button } from "src/components/Button";
import {
  DefaultErrors,
  failure,
  FailureOrSuccess,
  Maybe,
  success,
  UnexpectedError,
} from "src/core";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { ThirdPartyAuthentication } from "src/utils/thirdPartyAuthentication";

type Props = {
  label: "Sign in" | "Sign up";
  referralCode?: Maybe<string>;
  onSuccess: (
    user: BaseUserFields,
    firebaseUser: UserCredential,
    isNew: boolean
  ) => void;
  onError: ({ error, message }: any) => void;
};
export function AppleButton({
  label,
  onSuccess,
  onError,
  referralCode,
}: Props) {
  const [fetchMe] = useLazyQuery<Pick<Query, "me">>(api.users.me, {
    fetchPolicy: "no-cache",
  });
  const [createUser] = useMutation<Pick<Mutation, "createUser">>(
    api.users.create
  );

  const _create = useCallback(
    async (
      firebaseUser: UserCredential
    ): Promise<FailureOrSuccess<DefaultErrors, BaseUserFields>> => {
      try {
        if (!firebaseUser.user.email) {
          return failure(
            new UnexpectedError(
              "Sorry, this account doesn't have an email. You must sign in with a Apple account with an email."
            )
          );
        }

        const referredDomainUrl = window.location.hostname;

        const params: MutationCreateUserArgs = {
          email: firebaseUser.user.email,
          name: firebaseUser.user.displayName || "",
          phoneNumber: firebaseUser.user.phoneNumber,
          // avatarImageUrl: firebaseUser.user.photoURL,
          referralCode: referralCode || null,
        };

        const userResponse = await createUser({
          variables: params,
        });

        if (!userResponse.data?.createUser) {
          return failure(new Error("No user returned."));
        }

        // at this point the user has to already be signed in with firebase (auth set in the create header)
        const user = userResponse.data.createUser;

        return success(user.user as BaseUserFields);
      } catch (err) {
        return failure(new UnexpectedError(err));
      }
    },
    [createUser]
  );

  const _fetchMe = async (): Promise<
    FailureOrSuccess<DefaultErrors, BaseUserFields>
  > => {
    try {
      const response = await fetchMe();
      return success(response.data?.me as BaseUserFields);
    } catch (err) {
      return failure(new UnexpectedError(err));
    }
  };

  const _onSuccess = async (firebaseUser: UserCredential) => {
    const refetchMeResponse = await _fetchMe();

    // if there is a user, return success
    if (refetchMeResponse.isSuccess() && refetchMeResponse.value) {
      return onSuccess(refetchMeResponse.value, firebaseUser, false);
    }

    // otherwise we need to create before we call on success
    const response = await _create(firebaseUser);

    if (response.isFailure()) {
      return onError({
        error: response.error,
        message: response.error.message,
      });
    }

    return onSuccess(response.value, firebaseUser, true);
  };

  const theme = useTheme();

  return (
    <Button
      type="button"
      onClick={async () => {
        ThirdPartyAuthentication.apple().then(_onSuccess).catch(onError);
      }}
      style={{
        width: "100%",
        position: "relative",
        backgroundColor: "#000",
        border: `1px solid ${theme.background}`,
        height: "auto",
      }}
      padding="1rem 2rem"
    >
      <React.Fragment>
        <i className="fab fa-apple" style={{ color: colors.white }} />

        <Text color={colors.white} fontSize={16}>
          {label} with Apple
        </Text>
      </React.Fragment>
    </Button>
  );
}
